export const ThemeStyle = {
  MODERN: 'modern',
  STANDARD: 'standard',
};
export const ThemeStyleRadius = {
  MODERN: 30,
  STANDARD: 16,
};
export const ThemeMode = {
  LIGHT: 'light',
  DARK: 'dark',
};
export const LayoutType = {
  FULL_WIDTH: 'full-width',
  BOXED: 'boxed',
  FRAMED: 'framed',
};
export const MenuStyle = {
  DEFAULT: 'default',
  STANDARD: 'standard',
  ROUNDED: 'rounded',
  ROUNDED_REVERSE: 'rounded-reverse',
  CURVED_MENU: 'curved-menu',
};
export const LayoutDirection = {
  RTL: 'rtl',
  LTR: 'ltr',
};
export const NavStyle = {
  DEFAULT: 'default',
  MINI: 'mini',
};
export const FooterType = {
  FIXED: 'fixed',
  FLUID: 'fluid',
};
export const HeaderType = {
  FLUID: 'fluid',
  FIXED: 'fixed',
};
export const RouteTransition = {
  NONE: 'none',
  alpha: 'alpha',
  SLIDE_LEFT: 'slideLeft',
  SLIDE_RIGHT: 'slideRight',
  SLIDE_UP: 'slideUp',
  SLIDE_DOWN: 'slideDown',
};
export const Fonts = {
  LIGHT: '300',
  REGULAR: '400',
  MEDIUM: '500',
  SEMI_BOLD: '600',
  BOLD: '700',
};

export const AuthType = {
  FIREBASE: 'firebase',
  AWS_COGNITO: 'aws_cognito',
  AUTH0: 'auth0',
  JWT_AUTH: 'jwt_auth',
};

export const RoutePermittedRole = {
  Admin: 'admin',
  User: 'user',
};

export const TicketStatusType = {
  ToDo: 1,
  OnHold: 2,
  InProgress: 3,
  Completed: 4,
  Discarded: 5,
  AwaitingDependency: 6,
  CRMCheckLaterListingReviewUpdatedByAppUser: 104,
  CRMRejectedListingReviewUpdatedByAppUser: 105,
  EditorCheckLaterListingReviewUpdatedFromApp: 114,
};

export const TicketSubTypes = {
  NoAnswer: 1,
  Closed: 2,
  CustomerBusyATM: 3,
  BadPhotos: 4,
  WrongInformation: 5,
  MissingBrand: 6,
  MissingModel: 7,
  MissingEngine: 8,
  MissingTrim: 9,
  MissingOther: 10,
  WaitingForPhotographerImages: 11,
  WrongPrice: 12,
  UpdatedFromIQPlus: 13,
};

export const CallCardSubjectTypes = {
  BadPhotos: 1,
  MissingInformation: 2,
  WrongPrice: 3,
  PackageUpgrade: 4,
  AskingForSupport: 5,
  CustomerNotSatisfiedWithResults: 6,
  ListingSetupUpdate: 7,
  CustomerFollowUp: 8,
  ListingPriceChange: 9,
};

export const TicketAssignmentType = {
  User: 1,
  Group: 2,
};

export const TicketAssignmentTypes = [
  {
    ID: TicketAssignmentType.User,
    Text: 'User',
  },
  {
    ID: TicketAssignmentType.Group,
    Text: 'Group',
  },
];

export const TicketType = {
  General: 1,
  CarPriceChanged: 2,
  ManualSocialMediaPost: 3,
  ManualFacebookPost: 4,
  ManualInstagramPost: 5,
  MissedCustomerCall: 7,
  DeletedCar: 10,
  SoldCar: 11,
  CallForUpgradeBasicPackage: 13,
  ManualFacebookSingleImagePost: 14,
  FacebookBoost: 15,
  CallForUpgradeBoostPackage: 16,
};

export const GroupAssignment = {
  CRM: 1,
  Sales: 2,
  Operation: 3,
  ManualSocialMediaPost: 4,
  QualityControl: 5,
  Marketing: 6,
};

export const DynamicFields = {
  PhoneNumber: 'PhoneNumber',
  FacebookLink: 'FacebookLink',
};

export const GeneralBoardTicketTypes = [
  {
    ID: TicketType.General,
    Text: 'General',
  },
  {
    ID: TicketType.CallForUpgradeBasicPackage,
    Text: 'Call for Upgrade - Basic Package',
  },
  {
    ID: TicketType.CallForUpgradeBoostPackage,
    Text: 'Call for Upgrade - Boost Package',
  },
  {
    ID: TicketType.FacebookBoost,
    Text: 'Facebook Boost',
  },
  {
    ID: TicketType.SoldCar,
    Text: 'Sold Listings',
  },
  {
    ID: TicketType.DeletedCar,
    Text: 'Delete Listings',
  },
];

export const CreatedByMeBoardTicketTypes = [
  {
    ID: TicketType.General,
    Text: 'General',
  },
  {
    ID: TicketType.ManualSocialMediaPost,
    Text: 'Social Media Post',
  },
  {
    ID: TicketType.ManualFacebookPost,
    Text: 'Facebook Post',
  },
  {
    ID: TicketType.ManualInstagramPost,
    Text: 'Instagram Post',
  },
  {
    ID: TicketType.ManualFacebookSingleImagePost,
    Text: 'Facebook Single Image Post',
  },
];

export const AssignedToMeBoardTicketTypes = [
  {
    ID: TicketType.General,
    Text: 'General',
  },
  {
    ID: TicketType.CallForUpgradeBasicPackage,
    Text: 'Call for Upgrade - Basic Package',
  },
  {
    ID: TicketType.CallForUpgradeBoostPackage,
    Text: 'Call for Upgrade - Boost Package',
  },
  {
    ID: TicketType.SoldCar,
    Text: 'Sold Listings',
  },
  {
    ID: TicketType.DeletedCar,
    Text: 'Delete Listings',
  },

  {
    ID: TicketType.ManualSocialMediaPost,
    Text: 'Social Media Post',
  },
  {
    ID: TicketType.ManualFacebookPost,
    Text: 'Facebook Post',
  },
  {
    ID: TicketType.ManualInstagramPost,
    Text: 'Instagram Post',
  },
  {
    ID: TicketType.ManualFacebookSingleImagePost,
    Text: 'Facebook Single Image Post',
  },
  {
    ID: TicketType.FacebookBoost,
    Text: 'Facebook Boost',
  },
  {
    ID: TicketType.MissedCustomerCall,
    Text: 'Missed Call',
  },
  {
    ID: TicketType.CarPriceChanged,
    Text: 'Car Price Changed',
  },
];

export const DiscardedBoardTicketTypes = [
  {
    ID: TicketType.General,
    Text: 'General',
  },
  {
    ID: TicketType.CallForUpgradeBasicPackage,
    Text: 'Call for Upgrade - Basic Package',
  },
  {
    ID: TicketType.CallForUpgradeBoostPackage,
    Text: 'Call for Upgrade - Boost Package',
  },
  {
    ID: TicketType.SoldCar,
    Text: 'Sold Listings',
  },
  {
    ID: TicketType.DeletedCar,
    Text: 'Delete Listings',
  },

  {
    ID: TicketType.ManualSocialMediaPost,
    Text: 'Social Media Post',
  },
  {
    ID: TicketType.ManualFacebookPost,
    Text: 'Facebook Post',
  },
  {
    ID: TicketType.ManualInstagramPost,
    Text: 'Instagram Post',
  },
  {
    ID: TicketType.ManualFacebookSingleImagePost,
    Text: 'Facebook Single Image Post',
  },
  {
    ID: TicketType.FacebookBoost,
    Text: 'Facebook Boost',
  },
  {
    ID: TicketType.CarPriceChanged,
    Text: 'Car Price Changed',
  },
];

export const CheckLaterBoardTicketTypes = [
  {
    ID: TicketType.General,
    Text: 'General',
  },
  {
    ID: TicketType.CallForUpgradeBasicPackage,
    Text: 'Call for Upgrade - Basic Package',
  },
  {
    ID: TicketType.CallForUpgradeBoostPackage,
    Text: 'Call for Upgrade - Boost Package',
  },
];

export const TicketTypes = [
  {
    ID: TicketType.General,
    Text: 'General',
  },
  {
    ID: TicketType.CarPriceChanged,
    Text: 'Car Price Changed',
  },
  {
    ID: TicketType.ManualSocialMediaPost,
    Text: 'Manual Social Media Post',
  },
  {
    ID: TicketType.ManualFacebookPost,
    Text: 'Manual Facebook Post',
  },
  {
    ID: TicketType.ManualInstagramPost,
    Text: 'Manual Instagram Post',
  },
];

export const SocialMediaTicketTypes = [
  {
    ID: TicketType.ManualSocialMediaPost,
    Text: 'Facebook and Instagram (Multi)',
  },
  {
    ID: TicketType.ManualFacebookPost,
    Text: 'Facebook (Multi)',
  },
  {
    ID: TicketType.ManualInstagramPost,
    Text: 'Instagram (Multi)',
  },
  {
    ID: TicketType.ManualFacebookSingleImagePost,
    Text: 'Facebook (Single)',
  },
];

export const HiddenCommentTicketTypes = () => {
  return [
    TicketType.CarPriceChanged,
    TicketType.ManualFacebookPost,
    TicketType.ManualFacebookSingleImagePost,
    TicketType.ManualInstagramPost,
    TicketType.ManualSocialMediaPost,
  ];
};

export const TicketPriorityType = {
  Low: 1,
  Medium: 2,
  High: 3,
};

export const TicketPriorityTypes = [
  {
    ID: TicketPriorityType.Low,
    Text: 'Low',
  },
  {
    ID: TicketPriorityType.Medium,
    Text: 'Medium',
  },
  {
    ID: TicketPriorityType.High,
    Text: 'High',
  },
];

export const TicketCommentAdminTypes = {
  System: 1,
  SystemAdmin: 2,
};

export const TicketSourceOperatorTypes = {
  System: 1,
  SystemAdmin: 2,
  AppUser: 3,
};

export const TicketModifierType = {
  System: 1,
  SystemAdmin: 2,
  AppUser: 3,
};

export const TicketCreationContexts = {
  AdminSystemCRMPendingSection: 1,
  AdminSystemPBXSection: 2,
  AdminSystemEditorPendingSection: 3,
  AdminSystemManageListingSection: 4,
  IQCarsApp: 5,
  IQPlusApp: 6,
  SystemControlPanelManageCar: 7,
  SystemControlPanelPendingCar: 8,
  SystemControlPanelDraftSection: 9,
  AdminSystemTicketSection: 10,
};

export const CurrencyType = {
  Dollar: 1,
  IraqiDinar: 2,
};

export const CurrencyTypes = [
  {
    ID: CurrencyType.Dollar,
    Text: 'Dollar',
  },
  {
    ID: CurrencyType.IraqiDinar,
    Text: 'IraqiDinar',
  },
];

export const PrivateAdDraftTypes = {
  Draft: 1,
  Basic: 2,
  TransferredToListing: 3,
};

export const PrivateAdTypes = {
  Boost: 1,
  SuperBoost: 2,
  Basic: 4,
};

export const PrivateAdTableType = {
  FromDraftTable: 1,
  FromCarTable: 2,
};

export const Language = {
  English: 'en',
  Arabic: 'ar',
  Kurdish: 'ku',
};

export const LanguageConfiguration = [
  {
    value: Language.English,
    label: 'English',
  },
  {
    value: Language.Arabic,
    label: 'Arabic',
  },
  {
    value: Language.Kurdish,
    label: 'Kurdish',
  },
];

export const ListingType = {
  HasCredit: 1,
};

export const ListingTypesConfiguration = [
  {
    value: ListingType.HasCredit,
    label: 'Has Credit',
  },
];

export const ChangeStatusType = {
  Discard: 1,
  CheckLater: 2,
  Rejected: 3,
};

export const CRMListingBoardType = {
  NewListing: 1,
  CheckLater: 2,
  Rejected: 3,
  Discarded: 4,
};

export const EditorListingBoardType = {
  ShowroomListing: 1,
  PrivateListing: 2,
  CheckLater: 3,
  FixedAfterRejection: 4,
  Unpublished: 5,
};

export const TicketBoardType = {
  AssignedToMe: 1,
  General: 2,
  CheckLater: 3,
  SocialMediaPost: 4,
  MissedCall: 5,
  CarPriceChanged: 6,
  CreatedByMe: 7,
  Discarded: 7,
};

export const AdminSystemStatus = {
  NewListing: 1,
  CRMCheckLater: 2,
  TransferredToPendingListings: 3,
  Discarded: 4,
  UnderEditorReview: 5,
  RejectedToCRM: 6,
  Published: 7,
  FixedAfterRejection: 8,
  EditorCheckLater: 9,
  Unpublished: 10,
};

export const getCRMPendingStatuses = () => {
  return [AdminSystemStatus.NewListing, AdminSystemStatus.CRMCheckLater, AdminSystemStatus.RejectedToCRM];
};

export const getEditorPendingStatuses = () => {
  return [
    AdminSystemStatus.UnderEditorReview,
    AdminSystemStatus.FixedAfterRejection,
    AdminSystemStatus.EditorCheckLater,
    AdminSystemStatus.Unpublished,
  ];
};

export const checkPendingStatus = (status) => {
  if (getCRMPendingStatuses().includes(status)) {
    return 'CRM Pending';
  } else if (getEditorPendingStatuses().includes(status)) {
    return 'Editor Pending';
  }
};

export const ListingsFilterType = {
  All: 0,
  Boost: 1,
  SuperBoost: 2,
  Basic: 4,
  PrivateListings: 11,
  ShowroomListings: 12,
};

export const ListingsFilterTypes = [
  { value: ListingsFilterType.All, label: 'All' },
  { value: ListingsFilterType.ShowroomListings, label: 'Showroom Listings' },
  { value: ListingsFilterType.PrivateListings, label: 'Private Listings' },
  { value: ListingsFilterType.Basic, label: 'Basic' },
  { value: ListingsFilterType.Boost, label: 'Boost' },
  { value: ListingsFilterType.SuperBoost, label: 'Super Boost' },
];

export const CallSource = {
  CallCenterAuto: 1,
  WhatsApp: 2,
  ManualPhoneCall: 3,
  CallCenterManual: 4,
};

export const CallDictionaryKey = {
  AdminSystemCRMSection: 1,
  AdminSystemManageListingSection: 4,
  AdminSystemTicketSectionCallForUpgradeTicket: 5,
  AdminSystemTicketSectionMissedCallTicket: 6,
  AdminSystemTicketSectionCarPriceChangedTicket: 7,
};

export const PackageUpgradeCallResponseValue = {
  CustomerUpgradedThePackage: 1,
  CustomerWillUpgradeThePackageLater: 2,
  CustomerWillNotUpgradeThePackage: 3,
  NoResponse: 9,
};

export const CallSourcesConfiguration = [
  { ID: CallSource.WhatsApp, Text: 'WhatsApp' },
  { ID: CallSource.ManualPhoneCall, Text: 'Manual Phone Call' },
];

export const CallCardStatus = {
  Answered: 1,
  Voicemail: 2,
  Failed: 3,
  Busy: 4,
  NoAnswer: 5,
};

export const CallStatusConfiguration = [
  { ID: CallCardStatus.Answered, Text: 'Answered' },
  { ID: CallCardStatus.Voicemail, Text: 'Voicemail' },
  { ID: CallCardStatus.Failed, Text: 'Failed' },
  { ID: CallCardStatus.Busy, Text: 'Busy' },
  { ID: CallCardStatus.NoAnswer, Text: 'No Answer' },
];

export const CallType = {
  Inbound: 1,
  Outbound: 2,
};

export const FilterBySubscription = {
  SubscribedShowrooms: 1,
  NonSubscribedShowrooms: 2,
};

export const IsAddedBy = {
  All: 0,
  Agent: 1,
  ShowroomManager: 2,
};

export const AddedByArray = [
  { value: IsAddedBy.All, label: 'All' },
  { value: IsAddedBy.Agent, label: 'Agent' },
  { value: IsAddedBy.ShowroomManager, label: 'Showroom Manager' },
];

export const ListingStatus = {
  Sold: 1,
  NotSold: 2,
  Deleted: 3,
  Expired: 4,
  FixedOnTop: 5,
  Published: 6,
  FixedOnTopAndActive: 7,
  HasVideo: 8,
};

export const Listingstatuses = [
  { value: ListingStatus.Published, label: 'Published' },
  { value: ListingStatus.Sold, label: 'Sold' },
  { value: ListingStatus.NotSold, label: 'Not Sold' },
  { value: ListingStatus.Deleted, label: 'Deleted' },
  { value: ListingStatus.Expired, label: 'Expired' },
  { value: ListingStatus.FixedOnTop, label: 'Fixed On Top' },
  { value: ListingStatus.FixedOnTopAndActive, label: 'Fixed On Top & Active' },
  { value: ListingStatus.HasVideo, label: 'Has Video' },
];

const packageStyles = {
  Draft: {
    backgroundColor: '#686D76', // Example color for Draft
    name: 'Draft',
  },
  Boost: {
    backgroundColor: '#F08800',
    name: 'Boost',
  },
  SuperBoost: {
    backgroundColor: '#00A520',
    name: 'Super Boost',
  },
  Basic: {
    backgroundColor: '#333333',
    name: 'Basic',
  },
  TransferredToListing: {
    backgroundColor: '#2196f3', // Example color for TransferredToListing
    name: 'Transferred',
  },
};

export const PrivateListingPackageBackgroundColor = (listing) => {
  if (listing?.PrivateAdTableTypeId === PrivateAdTableType.FromDraftTable) {
    return listing?.PrivateAdDraftType === PrivateAdDraftTypes.Draft
      ? packageStyles.Draft.backgroundColor
      : listing?.PrivateAdDraftType === PrivateAdDraftTypes.Basic
      ? packageStyles.Basic.backgroundColor
      : listing?.PrivateAdDraftType === PrivateAdDraftTypes.TransferredToListing
      ? packageStyles.TransferredToListing.backgroundColor
      : '';
  } else if (listing?.PrivateAdTableTypeId === PrivateAdTableType.FromCarTable) {
    return listing?.PrivateAdType === PrivateAdTypes.Boost
      ? packageStyles.Boost.backgroundColor
      : listing?.PrivateAdType === PrivateAdTypes.SuperBoost
      ? packageStyles.SuperBoost.backgroundColor
      : listing?.PrivateAdType === PrivateAdTypes.Basic
      ? packageStyles.Basic.backgroundColor
      : '';
  }
};

export const PrivateListingPackageName = (listing) => {
  if (listing?.PrivateAdTableTypeId === PrivateAdTableType.FromDraftTable) {
    return listing?.PrivateAdDraftType === PrivateAdDraftTypes.Draft
      ? packageStyles.Draft.name
      : listing?.PrivateAdDraftType === PrivateAdDraftTypes.Basic
      ? packageStyles.Basic.name
      : listing?.PrivateAdDraftType === PrivateAdDraftTypes.TransferredToListing
      ? packageStyles.TransferredToListing.name
      : '';
  } else if (listing?.PrivateAdTableTypeId === PrivateAdTableType.FromCarTable) {
    return listing?.PrivateAdType === PrivateAdTypes.Boost
      ? packageStyles.Boost.name
      : listing?.PrivateAdType === PrivateAdTypes.SuperBoost
      ? packageStyles.SuperBoost.name
      : listing?.PrivateAdType === PrivateAdTypes.Basic
      ? packageStyles.Basic.name
      : '';
  }
};

export const GetCallCardSubjectTitle = (callCardSubjectId) => {
  if (callCardSubjectId == CallCardSubjectTypes.BadPhotos) {
    return 'Bad Photos';
  } else if (callCardSubjectId == CallCardSubjectTypes.MissingInformation) {
    return 'Missing Information';
  } else if (callCardSubjectId == CallCardSubjectTypes.WrongPrice) {
    return 'Wrong Price';
  } else if (callCardSubjectId == CallCardSubjectTypes.PackageUpgrade) {
    return 'Package Upgrade';
  } else if (callCardSubjectId == CallCardSubjectTypes.AskingForSupport) {
    return 'Asking For Support';
  } else if (callCardSubjectId == CallCardSubjectTypes.CustomerNotSatisfiedWithResults) {
    return 'Customer Not Satisfied With Results';
  } else if (callCardSubjectId == CallCardSubjectTypes.ListingSetupUpdate) {
    return 'Listing Setup Update';
  } else if (callCardSubjectId == CallCardSubjectTypes.CustomerFollowUp) {
    return 'Customer Follow Up';
  } else {
    const entry = Object.entries(CallCardSubjectTypes).find(([, value]) => value === callCardSubjectId);
    return entry ? entry[0] : 'Unknown Subject';
  }
};

export const CheckTicketSubType = (ticketData) => {
  if (ticketData.TicketSubType == TicketSubTypes.NoAnswer) {
    return 'No answer';
  } else if (ticketData.TicketSubType == TicketSubTypes.Closed) {
    return 'Closed';
  } else if (ticketData.TicketSubType == TicketSubTypes.CustomerBusyATM) {
    return 'Customer busy at the moment';
  } else if (ticketData.TicketSubType == TicketSubTypes.BadPhotos) {
    return 'Bad photos';
  } else if (ticketData.TicketSubType == TicketSubTypes.WrongInformation) {
    return 'Wrong information';
  } else if (ticketData.TicketSubType == TicketSubTypes.MissingBrand) {
    return 'Missing brand';
  } else if (ticketData.TicketSubType == TicketSubTypes.MissingModel) {
    return 'Missing model';
  } else if (ticketData.TicketSubType == TicketSubTypes.MissingEngine) {
    return 'Missing engine';
  } else if (ticketData.TicketSubType == TicketSubTypes.MissingTrim) {
    return 'Missing trim';
  } else if (ticketData.TicketSubType == TicketSubTypes.MissingOther) {
    return 'Missing other';
  } else if (ticketData.TicketSubType == TicketSubTypes.WaitingForPhotographerImages) {
    return 'Waiting for photographer images';
  } else if (ticketData.TicketSubType == TicketSubTypes.WrongPrice) {
    return 'Wrong price';
  } else if (ticketData.TicketSubType == TicketSubTypes.UpdatedFromIQPlus) {
    return 'Updated from IQ Plus';
  } else {
    const entry = Object.entries(TicketSubTypes).find(([, value]) => value === ticketData.TicketSubType);
    return entry ? entry[0] : 'Unknown Type';
  }
};

export const checkListingStatus = (listingData) => {
  if (listingData.Deleted) return 'Deleted';
  if (listingData.Sold) return 'Sold';
  if (listingData.Expired) return 'Expired';
  if (listingData.IsPublished) return 'Published';
  if (listingData.AdminSystemStatus === AdminSystemStatus.Unpublished) return 'Unpublished';
  if (listingData.AdminSystemStatus === AdminSystemStatus.Discarded) return 'Discarded';
  // if (listingData.AdminSystemStatus === AdminSystemStatus.TransferredToPendingListings) return 'Transferred';
  if (getCRMPendingStatuses().includes(listingData.AdminSystemStatus)) return 'CRM Pending';
  if (getEditorPendingStatuses().includes(listingData.AdminSystemStatus)) return 'Editor Pending';
};

export const getStatusColor = (status) => {
  switch (status) {
    case 'Deleted':
      return '#D91656';
    case 'Sold':
      return '#FD8B51';
    case 'Expired':
      return '#222B36';
    case 'Published':
      return '#86D293';
    case 'Unpublished':
      return '#758694';
    case 'CRM Pending':
      return '#FFBF61';
    case 'Editor Pending':
      return '#FFBF61';
    default:
      return 'gray';
  }
};

export const Languages = [
  { label: 'English', key: 'en' },
  { label: 'Arabic', key: 'ar' },
  { label: 'Kurdish', key: 'ku' },
];

export const PermissionKeys = {
  Prodcut: {
    Read: 'Product.Read',
  },

  GeneralPermissionGroup: {
    PlayCallRecording: 'GeneralPermissionGroup.PlayCallRecording',
    MakeCallThroughCallCenter: 'GeneralPermissionGroup.MakeCallThroughCallCenter',
    CreateManualCallCard: 'GeneralPermissionGroup.CreateManualCallCard',
    SeeAllCalls: 'GeneralPermissionGroup.SeeAllCalls',
    AddCreditToUser: 'GeneralPermissionGroup.AddCreditToUser',
    ViewPowerBITicketSystemAnalytics: 'GeneralPermissionGroup.ViewPowerBITicketSystemAnalytics',
    ViewPowerBITicketSystemListingReviewProcess: 'GeneralPermissionGroup.ViewPowerBITicketSystemListingReviewProcess',
    ViewPowerBIActiveListingTracking: 'GeneralPermissionGroup.ViewPowerBIActiveListingTracking',
  },

  CRMListingsPermissionGroup: {
    ViewCRMListingsPage: 'CRMListingsPermissionGroup.ViewCRMListingsPage',
    ViewCRMListingsDetails: 'CRMListingsPermissionGroup.ViewCRMListingsDetails',
    EditCRMListingsDetails: 'CRMListingsPermissionGroup.EditCRMListingsDetails',
    PublishListingToPending: 'CRMListingsPermissionGroup.PublishListingToPending',
    UnlockCRMListings: 'CRMListingsPermissionGroup.UnlockCRMListings',
    ChangeFollowUpAssignment: 'CRMListingsPermissionGroup.ChangeFollowUpAssignment',
    ViewDiscardedDrafts: 'CRMListingsPermissionGroup.ViewDiscardedDrafts',
    RecoverDiscardedDrafts: 'CRMListingsPermissionGroup.RecoverDiscardedDrafts',
  },

  EditorPendingListingsPermissionGroup: {
    ViewPendingListingsPage: 'EditorPendingListingsPermissionGroup.ViewPendingListingsPage',
    ViewPendingListingsDetails: 'EditorPendingListingsPermissionGroup.ViewPendingListingsDetails',
    EditPendingListings: 'EditorPendingListingsPermissionGroup.EditPendingListings',
    PublishListingsToPublic: 'EditorPendingListingsPermissionGroup.PublishListingsToPublic',
    UnlockEditorListings: 'EditorPendingListingsPermissionGroup.UnlockEditorListings',
    DeleteListing: 'EditorPendingListingsPermissionGroup.DeleteListing',
    UnpublishListing: 'EditorPendingListingsPermissionGroup.UnpublishListing',
    ChangeFollowUpAssignment: 'EditorPendingListingsPermissionGroup.ChangeFollowUpAssignment',
  },

  TicketPermissionGroup: {
    ViewTicketsPage: 'TicketPermissionGroup.ViewTicketsPage',
    ReadCompletedTickets: 'TicketPermissionGroup.ReadCompletedTickets',
    ReadDiscardedTickets: 'TicketPermissionGroup.ReadDiscardedTickets',
    RecoverDiscardedTickets: 'TicketPermissionGroup.RecoverDiscardedTickets',
    ReadTicketDetails: 'TicketPermissionGroup.ReadTicketDetails',
    AddCommentToTickets: 'TicketPermissionGroup.AddCommentToTickets',
    CompleteTickets: 'TicketPermissionGroup.CompleteTickets',
    DiscardTickets: 'TicketPermissionGroup.DiscardTickets',
    CreateTickets: 'TicketPermissionGroup.CreateTickets',
    ChangeStatus: 'TicketPermissionGroup.ChangeStatus',
    ViewMissedCallTickets: 'TicketPermissionGroup.ViewMissedCallTickets',
    SetReminderForMissedCallTickets: 'TicketPermissionGroup.SetReminderForMissedCallTickets',
    UnlockTickets: 'TicketPermissionGroup.UnlockTickets',
    CompleteFacebookBoostTickets: 'TicketPermissionGroup.CompleteFacebookBoostTickets',
  },

  ManageListingsPermissionGroup: {
    ViewManageListingsPage: 'ManageListingsPermissionGroup.ViewManageListingsPage',
    ViewManageListingsDetails: 'ManageListingsPermissionGroup.ViewManageListingsDetails',
    ManageListingsEdit: 'ManageListingsPermissionGroup.ManageListingsEdit',
    UpgradePrivateListingPackage: 'ManageListingsPermissionGroup.UpgradePrivateListingPackage',
    ShareListing: 'ManageListingsPermissionGroup.ShareListing',
    DeleteListing: 'ManageListingsPermissionGroup.DeleteListing',
    UnpublishListing: 'ManageListingsPermissionGroup.UnpublishListing',
    CreateListing: 'ManageListingsPermissionGroup.CreateListing',
    UnlockListings: 'ManageListingsPermissionGroup.UnlockListings',
    PrintSpecsSheet: 'ManageListingsPermissionGroup.PrintSpecsSheet',
    RenewListings: 'ManageListingsPermissionGroup.RenewListings',
    FixListingsOnTop: 'ManageListingsPermissionGroup.FixListingsOnTop',
    RecoverSoldListing: 'ManageListingsPermissionGroup.RecoverSoldListing',
    RecoverDeletedListing: 'ManageListingsPermissionGroup.RecoverDeletedListing',
  },
};
