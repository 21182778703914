import React from 'react';
import { RoutePermittedRole, PermissionKeys } from '../../constants/AppEnums';

const Users = React.lazy(() => import('modules/Pages/ManageAccess/Users'));
const Roles = React.lazy(() => import('modules/Pages/ManageAccess/Roles'));
const Agents = React.lazy(() => import('modules/Pages/ManageAccess/Agents'));
const AssignRoles = React.lazy(() => import('modules/Pages/ManageAccess/AssignRoles'));
const AssignmentGroups = React.lazy(() => import('modules/Pages/ManageAccess/AssignmentGroups'));
const Tasks = React.lazy(() => import('modules/Pages/Tickets'));

const TicketSystemAnalysis = React.lazy(() => import('modules/Pages/Tickets/Reports/TicketSystemAnalysis'));
const ListingReviewProcess = React.lazy(() => import('modules/Pages/Tickets/Reports/ListingReviewProcess'));
const ActiveListingTracking = React.lazy(() => import('modules/Pages/Tickets/Reports/ActiveListingTracking'));

const CRMPendingListings = React.lazy(() => import('modules/Pages/CRM/PendingListings'));

const EditorPendingListings = React.lazy(() => import('modules/Pages/Editor/EditorPendingListings'));

const CustomAudienceTargeting = React.lazy(() => import('modules/Pages/CustomAudienceTargeting'));

const ManageListings = React.lazy(() => import('modules/Pages/ManageListings'));

const ManageShowrooms = React.lazy(() => import('modules/Pages/ManageShowrooms'));

const DiscardedListings = React.lazy(() => import('modules/Pages/Discarded/DiscardedListings'));
const DiscardedTickets = React.lazy(() => import('modules/Pages/Discarded/DiscardedTickets'));

export const AuthorizedPagesConfigs = [
  {
    permitedPermissionKey: PermissionKeys.Prodcut.Read,
    requireSuperAdmin: true,
    path: '/manage-access/users',
    element: <Users />,
  },
  {
    requireSuperAdmin: true,
    path: '/manage-access/agents',
    element: <Agents />,
  },
  {
    requireSuperAdmin: true,
    path: '/manage-access/roles',
    element: <Roles />,
  },
  {
    requireSuperAdmin: true,
    path: '/manage-access/assign-roles',
    element: <AssignRoles />,
  },
  {
    permitedPermissionKey: PermissionKeys.Prodcut.Read,
    requireSuperAdmin: true,
    path: '/manage-access/assign-groups',
    element: <AssignmentGroups />,
  },
  {
    permitedPermissionKey: PermissionKeys.TicketPermissionGroup.ViewTicketsPage,
    requireSuperAdmin: false,
    path: '/tickets/tasks',
    element: <Tasks />,
  },

  {
    permitedPermissionKey: PermissionKeys.GeneralPermissionGroup.ViewPowerBITicketSystemAnalytics,
    requireSuperAdmin: false,
    path: '/analytics/ticket-analytics',
    element: <TicketSystemAnalysis />,
  },
  {
    permitedPermissionKey: PermissionKeys.GeneralPermissionGroup.ViewPowerBITicketSystemListingReviewProcess,
    requireSuperAdmin: false,
    path: '/analytics/listing-review-process',
    element: <ListingReviewProcess />,
  },
  {
    permitedPermissionKey: PermissionKeys.GeneralPermissionGroup.ViewPowerBIActiveListingTracking,
    requireSuperAdmin: false,
    path: '/analytics/active-listing-tracking',
    element: <ActiveListingTracking />,
  },
  {
    permitedPermissionKey: PermissionKeys.CRMListingsPermissionGroup.ViewCRMListingsPage,
    requireSuperAdmin: false,
    path: '/crm/pending-listings',
    element: <CRMPendingListings />,
  },

  {
    permitedPermissionKey: PermissionKeys.EditorPendingListingsPermissionGroup.ViewPendingListingsPage,
    requireSuperAdmin: false,
    path: '/editor/pending-listings',
    element: <EditorPendingListings />,
  },

  // {
  //   // permitedPermissionKey: PermissionKeys.EditorPendingListingsPermissionGroup.ViewPendingListingsPage,
  //   requireSuperAdmin: false,
  //   path: '/custom-audience-targeting',
  //   element: <CustomAudienceTargeting />,
  // },
  {
    permitedPermissionKey: PermissionKeys.ManageListingsPermissionGroup.ViewManageListingsPage,
    requireSuperAdmin: false,
    path: '/manage-listings',
    element: <ManageListings />,
  },

  {
    // permitedPermissionKey: PermissionKeys.ManageListingsPermissionGroup.ViewManageListingsPage,
    requireSuperAdmin: false,
    path: '/manage-showrooms',
    element: <ManageShowrooms />,
  },

  {
    permitedPermissionKey: PermissionKeys.TicketPermissionGroup.ReadDiscardedTickets,
    requireSuperAdmin: false,
    path: '/discarded/discarded-tickets',
    element: <DiscardedTickets />,
  },

  {
    permitedPermissionKey: PermissionKeys.CRMListingsPermissionGroup.ViewDiscardedDrafts,
    requireSuperAdmin: false,
    path: '/discarded/discarded-listings',
    element: <DiscardedListings />,
  },
];
